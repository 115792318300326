<template>
  <div>
    <BaseHeader
      :title="name"
      :style-variant="17"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          {{ $t('landingpages.body-texts.0') }}
          <br>
          {{ $t('landingpages.body-texts.1') }}
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>

    <v-row
      v-if="landingpage"
      class="mb-2"
    >
      <v-col
        cols="12"
        lg="4"
        md="6"
        class="py-2 pr-3 pr-md-2"
      >
        <v-card
          outlined
          class="h-full"
        >
          <v-card-title>
            <h3 class="overline grey--text text--darken-1">
              {{ $t('statistics.title') }}
            </h3>
          </v-card-title>
          <v-card-text
            v-if="hasFilter(landingpageType)"
            class="px-6 primary--text"
            style="cursor:pointer"
            @click="handleClick(landingpageType)"
          >
            <span class="text-h2">
              {{ conversionCount }}
            </span>
            <span> x {{ $t(conversionLabel(landingpageType)) }}</span>
          </v-card-text>
          <v-card-text
            v-else
            class="px-6"
          >
            <span class="text-h2">
              {{ conversionCount }}
            </span>
            <span> x {{ conversionLabel(landingpageType) }}</span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="5"
        md="6"
        class="py-2"
      >
        <v-card
          outlined
          class="h-full"
        >
          <v-card-title>
            <h3 class="overline grey--text text--darken-1">
              {{ $t('landingpage.urls') }}
            </h3>
          </v-card-title>
          <v-card-text class="px-6 text-body-1">
            <div class="d-flex">
              <span
                class="mr-1 grey--text text--darken-1 text-no-wrap"
              >{{ $t('landingpage.link') }}: </span>
              <a
                :href="originalUrl"
                :title="prettyOriginalUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="d-inline-block text-truncate"
              >
                {{ prettyOriginalUrl }}
              </a>
            </div>

            <div class="d-flex">
              <span class="mr-1 grey--text text--darken-1 text-no-wrap">{{ $t('landingpage.custom-url') }}: </span>
              <a
                v-if="customUrl"
                :href="customUrl"
                :title="customUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="d-inline-block text-truncate"
              >
                {{ prettyCustomUrl }}
              </a>
              <div
                v-else
                class="d-flex"
              >
                <span class="mr-2 grey--text">{{ $t('landingpage.no-url') }}</span>
                <information-button
                  :title="$t('landingpage.information-button.title')"
                  :content="$t('landingpage.information-button.content')"
                  :link="helpLinks.customUrl"
                  :link-text="$t('landingpage.information-button.link-text')"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="3"
        class="py-2 pl-3 pl-lg-2"
      >
        <v-card
          outlined
          class="h-full"
        >
          <v-card-title>
            <h3 class="overline grey--text text--darken-1">
              {{ $t('landingpage.integration.title') }}
            </h3>
          </v-card-title>
          <v-card-text class="px-6">
            <p>{{ $t('landingpage.integration.subtitle', { readablePrefix, name }) }}</p>
            <SnippetModal
              :landingpage-slug="slug"
              :readable-prefix="readablePrefix"
              :readable-landingpage="name"
              :company-slug="companySlug"
              :company-site-url="companySiteUrl"
              :landingpage-type="landingpageType"
              small
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Permission permission="material:read">
      <v-card
        v-if="landingpage && hasMaterialsToShow && hasMaterialsFeature"
        outlined
      >
        <v-card-title>
          <h3 class="overline grey--text text--darken-1">
            {{ $t('landingpage.materials.title') }} ({{ materialCount }})
          </h3>
        </v-card-title>
        <v-card-subtitle>
          {{ $t('landingpage.materials.subtitle') }}
        </v-card-subtitle>
        <v-card-text class="px-4">
          <div class="pb-2">
            <template v-if="downloads.length > 0">
              <h3 class="overline">
                {{ $t('landingpage.materials.download-documents') }}
              </h3>
              <v-row>
                <v-col
                  v-for="{previewFile} in coverFiles"
                  :key="previewFile.url"
                  :cols="12"
                  :sm="4"
                  :md="3"
                >
                  <MaterialCard
                    name="Cover"
                    :url="setQueryParams(previewFile.cdnUrl, { attachment: '' })"
                    :thumbnail-url="previewFile.cdnUrl"
                  />
                </v-col>
                <template
                  v-for="material in downloads"
                >
                  <v-col
                    v-for="{file, previewFile} in material.files"
                    :key="file.url"
                    :cols="12"
                    :sm="4"
                    :md="3"
                  >
                    <MaterialCard
                      :name="material.displayName"
                      :url="file.cdnUrl"
                      :thumbnail-url="previewFile.cdnUrl"
                    />
                  </v-col>
                </template>
              </v-row>
            </template>

            <template v-if="basicAds.length > 0">
              <h3 class="mt-4 overline">
                {{ $t('landingpage.materials.basic-ads') }}
              </h3>

              <v-row>
                <template
                  v-for="material in basicAds"
                >
                  <v-col
                    v-for="{file, previewFile} in material.files"
                    :key="file.url"
                    :cols="12"
                    :sm="4"
                    :md="3"
                  >
                    <MaterialCard
                      :name="material.displayName"
                      :url="file.cdnUrl"
                      :thumbnail-url="previewFile.cdnUrl"
                    />
                  </v-col>
                </template>
              </v-row>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </Permission>

    <v-card
      v-if="landingpage && !hasMaterialsToShow && hasMaterialsFeature"
      outlined
    >
      <no-content-screen
        :title="$t('landingpage.materials.no-content-screen')"
        :sub-title="noContentSubtitle"
      />
    </v-card>

    <v-card
      v-if="landingpage && hasStaticMaterialsFeature"
      outlined
    >
      <v-card-title>
        <h3 class="overline grey--text text--darken-1">
          {{ $t('landingpage.materials.title') }} ({{ lightMaterials[landingpage.slug].length }})
        </h3>
      </v-card-title>
      <v-card-subtitle>
        {{ $t('landingpage.materials.subtitle') }}
      </v-card-subtitle>
      <v-card-text class="px-4">
        <div class="pb-2">
          <template v-if="lightMaterials[landingpage.slug].length > 0">
            <h3 class="mt-4 overline">
              {{ $t('landingpage.materials.basic-ads') }}
            </h3>

            <v-row>
              <template
                v-for="material in lightMaterials[landingpage.slug]"
              >
                <v-col
                  v-for="{file, previewFile} in material.files"
                  :key="file.url"
                  :cols="12"
                  :sm="4"
                  :md="3"
                >
                  <MaterialCard
                    :name="material.displayName"
                    :url="file.cdnUrl"
                    :thumbnail-url="previewFile.cdnUrl"
                  />
                </v-col>
              </template>
            </v-row>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SnippetModal from '../landingpages/snippet/index.vue'
import MaterialCard from '../landingpages/MaterialCard'
import LANDINGPAGE from './Landingpage.gql'
import COMPANY_BASE from './CompanyBase.gql'
import { TOOLS } from '@/lib/tools'
import BaseHeader from '@/components/BaseHeader.vue'
import NoContentScreen from '@/components/NoContentScreen.vue'
import helpLinks from '@/lib/helpLinks'
import { setQueryParams } from '@/lib/url'
import InformationButton from '@/components/InformationButton.vue'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'
import HeaderDesign from './HeaderDesign.vue'
import lightMaterials from '@/lib/lightMaterials' // temporary solution for light-product, see comment in lightMaterials.js
import Permission from '@/components/Permission'
import feature from '@/mixins/feature'

const filterOnlyFiles = material => material.hasFile

const ARTICLE = {
  VALUATION: 'landingpage.integration.prefix.valuation',
  RETURN_CALL: 'landingpage.integration.prefix.return-call',
  LIFE_ANNUITY: 'landingpage.integration.prefix.life-annuity',
  PARTIAL_SALE: 'landingpage.integration.prefix.partial-sale',
  PLOT_VALUATION: 'landingpage.integration.prefix.plot-valuation',
  QUIZZARD: 'landingpage.integration.prefix.quizzard',
  GUIDE: 'landingpage.integration.prefix.guide',
  CHECKLIST: 'landingpage.integration.prefix.checklist'
}

export default {
  components: {
    SnippetModal,
    MaterialCard,
    BaseHeader,
    NoContentScreen,
    InformationButton,
    HeaderDesign,
    Permission
  },

  mixins: [feature],

  data () {
    return {
      helpLinks,
      lightMaterials
    }
  },

  apollo: {
    landingpage: {
      query: LANDINGPAGE,
      variables () {
        return {
          input: {
            landingpageSlug: this.$route.params.slug
          }
        }
      }
    },
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },

  computed: {
    noContentSubtitle () { return !this.hasMaterialsFeature ? 'Mit Materialien noch erfolgreicher unterwegs' : 'Diese werden in Kürze für Sie bereitgestellt' },
    name () { return this.landingpage?.name || 'Landingpage' },
    slug () { return this.landingpage?.slug || '' },
    conversionCount () { return this.landingpage?.conversionCount || 0 },
    originalUrl () { return this.landingpage?.originalUrl || '' },
    customUrl () { return this.landingpage?.customUrl || '' },
    landingpageType () { return this.landingpage?.type || 'GUIDE' },
    companySlug () { return this.company.slug },
    companySiteUrl () { return this.company?.site.url },
    materials () { return this.landingpage?.materials || [] },
    materialCount () {
      let count = this.coverFiles.length
      this.basicAds.forEach(basicAd => { count += basicAd.files.length })
      this.downloads.forEach(download => { count += download.files.length })
      return count
    },
    breadcrumbItems () {
      const type = this.landingpageType === 'GUIDE' ? 'guides' : this.landingpageType === 'CHECKLIST' ? 'checklists' : TOOLS.find(tool => tool.type === this.landingpageType) ? 'tools' : 'campaigns'
      return [
        { text: 'Landingpages', to: { path: `/landingpages#${type}` }, disabled: false, exact: true },
        { text: 'Details' }
      ]
    },
    coverFiles () {
      const material = this.materials.find(material => (
        material.type === 'DOWNLOAD' &&
        material.purpose === 'SCREEN'
      ))
      if (material && material.files) {
        return material.files
      }
      return []
    },

    downloads () {
      return this.materials
        .filter(filterOnlyFiles)
        .filter(material => material.type === 'DOWNLOAD')
    },

    basicAds () {
      return this.materials
        .filter(filterOnlyFiles)
        .filter(material => material.type === 'BASIC_AD')
    },

    hasMaterialsToShow () {
      return this.downloads.length > 0 || this.basicAds.length > 0
    },

    hasMaterials () {
      return this.materials?.length > 0
    },

    prettyOriginalUrl () {
      return this.originalUrl.replace(/^(https?:\/\/)/, '')
    },

    prettyCustomUrl () {
      if (!this.customUrl) {
        return ''
      }
      return this.customUrl.replace(/^(https?:\/\/)?/, '')
    },

    readablePrefix () {
      return this.$t(ARTICLE[this.landingpageType])
    },

    hasMaterialsFeature () {
      return this.isFeatureActive(this.featureNames.LANDINGPAGE) && this.getFeature(this.featureNames.LANDINGPAGE)?.config.hasMaterials
    },

    hasStaticMaterialsFeature () {
      return this.isFeatureActive(this.featureNames.LANDINGPAGE) && this.getFeature(this.featureNames.LANDINGPAGE)?.config.hasStaticMaterials
    }

  },
  methods: {
    setQueryParams,
    hasFilter (type) {
      return TOOLS.find(tool => tool.type === type)
    },
    conversionLabel (type) {
      return TOOLS.find(tool => tool.type === type)?.action || this.$t('labels.downloaded')
    },
    handleClick (type) {
      resetAllFilters()

      const tool = TOOLS.find(tool => tool.type === type)
      persistFilter(tool.key, true)

      this.$router.push('/leads?filter')
    }
  }
}
</script>
